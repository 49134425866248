export const types = {
  SET_FORM: 'SET_FORM',
  SET_FORMS_VIEW: 'SET_FORMS_VIEW',
  SET_SUBMISSION: 'SET_SUBMISSION',
  SET_SUBMISSIONS: 'SET_SUBMISSIONS',
  UNSET_SUBMISSION: 'UNSET_SUBMISSION',
  UNSET_SUBMISSIONS: 'UNSET_SUBMISSIONS',
  UPDATE_CUSTOMER: 'UPDATE_CUSTOMER',
  UPDATE_ORDER: 'UPDATE_ORDER',
}

export type UnsetSubmission = {
  type: string
  ref: number | string
}

export interface FormSubmission {
  id: number
  title: string
  ticket_number?: number
  basket_item_ref?: number
  data: JSON
}
