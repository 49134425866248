import Vue from 'vue'
import { MutationTree } from 'vuex'
import { Availability, Instance, PriceType, Zone } from '@Core/@types/skyway'
import {
  updateOrAddEntitiesForObject,
  updateOrAddEntitiesForArray,
} from '@Core/helpers/store-mutations'
import { filterValidMos } from '@Events/helpers/transformEvents'
import { types } from './types'
import { State, defaultState } from './state'

const getPriceTypeAvailability = (
  p: PriceType,
  availability: Availability[]
) => {
  const zone_refs =
    (p &&
      p.prices &&
      p.prices.reduce((acc: string[], pr) => {
        if (pr && pr.zone_ref) {
          acc.push(pr.zone_ref)
        }

        return acc
      }, [])) ||
    []

  return zone_refs.reduce((acc: number, zr) => {
    const avail = availability.find((a) => a && a.zone_ref === zr)

    if (avail && avail.availability) {
      acc = acc + avail.availability
    }

    return acc
  }, 0)
}

export const transformInstance = (state: State, instance: Instance) => {
  return filterValidMos(state, instance)
}

export const transformInstanceArray = (state: State, instances: Instance[]) => {
  return instances.map((i) => filterValidMos(state, i))
}

const mutations: MutationTree<State> = {
  [types.SET_INSTANCE](state: State, payload: Instance): void {
    state.instance = transformInstance(state, payload)
  },

  [types.SET_SELECTED](state: State, payload: Instance): void {
    state.selected = payload
  },

  [types.SET_INSTANCES](state: State, payload: Instance[]): void {
    if (Array.isArray(state.instances)) {
      updateOrAddEntitiesForArray(
        state.instances,
        transformInstanceArray(state, payload),
        (a, b) => a.instance_ref === b.instance_ref
      )
    } else if (state.instance && typeof state.instance === 'object') {
      updateOrAddEntitiesForObject(
        state.instances,
        transformInstanceArray(state, payload),
        'instance_ref',
        (a, b) => a.instance_ref === b.instance_ref
      )
    }
  },

  [types.SET_ZONE](state: State, payload: Zone): void {
    state.zone = payload
  },

  [types.SET_INSTANCE_PRICE_TYPES](state: State, payload: Instance): void {
    if (
      state.instance &&
      Number(state.instance.instance_ref) === Number(payload.instance_ref)
    ) {
      Vue.set(state.instance, 'price_types', payload.price_types)
    }
  },

  [types.SET_INSTANCE_AVAILABILITY](state: State, payload: Instance): void {
    if (
      state.instance &&
      Number(state.instance.instance_ref) === Number(payload.instance_ref)
    ) {
      Vue.set(state.instance, 'total_availability', payload.total_availability)
      Vue.set(state.instance, 'availability', payload.availability)

      if (state.instance.price_types) {
        for (const p in state.instance.price_types as PriceType[]) {
          const priceTypeAvailability = getPriceTypeAvailability(
            state.instance.price_types[p] as PriceType,
            payload.availability as Availability[]
          )
          Vue.set(
            state.instance.price_types[p],
            'availability',
            priceTypeAvailability
          )
        }
      }
      if (state.instances) {
        for (let i; i < state.instances.length; i++) {
          if (
            Number(state.instances[i].instance_ref) ===
            Number(payload.instance_ref)
          ) {
            Vue.set(
              state.instances[i],
              'total_availability',
              payload.total_availability
            )
            Vue.set(state.instances[i], 'availability', payload.availability)
          }
        }
      }
    }
  },

  [types.SET_INSTANCES_GROUPED_BY_MONTH](
    state: State,
    payload: { instances: Instance[]; month: string }
  ): void {
    const { month, instances } = payload

    state.instances = { [month]: transformInstanceArray(state, instances) }
  },

  [types.SET_INSTANCE_FILTERS](state: State, payload: string[]) {
    state.instanceFilters = payload
  },

  /**
   * Checks whether filters have been applied. If so, set filteredInstances to
   * the instances which meets the criteria.
   *
   * If not, filteredInstances becomes the entirety of the fetched instances.
   */
  [types.FILTER_INSTANCES](state: State) {
    if (state.instanceFilters.length === 1 && state.instances) {
      state.filteredInstances = Object.entries(state.instances).reduce(
        (instancesObj, [key, val]) => {
          const filteredVal = val.filter(
            (v) => v.event.type === state.instanceFilters[0]
          )
          return { ...instancesObj, [key]: [...filteredVal] }
        },
        {}
      )
    } else {
      state.filteredInstances = state.instances
    }
  },
  [types.RESET](state: State): void {
    Object.assign(state, defaultState())
  },
}

export default mutations
