import { DateRangeInput, Event, Series } from '@Core/@types/skyway'
import { WEB_MOS_REFS } from '@Tickets/store/seats/state'

export interface FilterModel {
  eventTypes: string[]
  eventTags: string[]
  accessibility: string[]
  focus: string[]
  age: string[]
  dateRange: DateRangeInput
  free: boolean
}

export interface State {
  event?: Event
  events: Event[]
  eventsFiltered: Event[]
  series: Series[]
  singleSeries?: Series
  selectedDate?: any
  calendar?: any[]
  selected?: Event
  tags?: string[]
  filters: FilterModel
  eventAvailability?: any[]
  web_mos_refs: string[]
}

/**
 * Initial state, empty array of events
 */
export const defaultState = (): State => ({
  event: undefined,
  events: [],
  eventsFiltered: [],
  series: [],
  singleSeries: undefined,
  selectedDate: undefined,
  calendar: [],
  selected: undefined,
  tags: undefined,
  filters: {
    eventTypes: [],
    eventTags: [],
    focus: [],
    age: [],
    accessibility: [],
    dateRange: {
      from: undefined,
      to: undefined,
    },
    free: false,
  },
  eventAvailability: undefined,
  web_mos_refs: WEB_MOS_REFS,
})

const state = defaultState

export default state
