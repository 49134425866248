import { ActionTree, ActionContext } from 'vuex'
import { RootState } from '@Core/store/types'
import { Instance, DateRangeInput } from '@Core/@types/skyway'
import { State } from './state'
import { types } from './types'

const actions: ActionTree<State, RootState> = {
  async getInstanceByRef(
    context: ActionContext<State, RootState>,
    instance_ref: string
  ): Promise<Instance> {
    const data = await this.$instances.getInstanceByRef(instance_ref)

    context.commit(types.SET_INSTANCE, data.getInstanceByRef)

    return data.getInstanceByRef
  },

  async getInstanceAvailability(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    const data = await this.$instances.getInstanceAvailability(id)

    context.commit(types.SET_INSTANCE_AVAILABILITY, data.instance)

    return data.instance
  },

  async getInstancesByDateRange(
    context: ActionContext<State, RootState>,
    params: {
      range: { from: Date; to: Date }
      brief: boolean
      type: string
      month: string
    }
  ): Promise<any> {
    if (context.state.instances && context.state.instances[params.month]) {
      return
    }

    const { range, brief, type } = params
    const fetchPolicy = !context.state.instances.length
      ? 'network-only'
      : 'cache-first'
    const data = await this.$instances.getInstancesByDateRange(
      range,
      brief,
      type,
      fetchPolicy
    )

    /**
     * Instances need to be grouped by month. This is so that we only show the
     * current month's instances.
     */

    if (data) {
      const instances = data.getInstancesByDateRange.map(
        (instance: Instance) => {
          return instance.event &&
            instance.event.series &&
            instance.event.series.title
            ? {
                ...instance,
                series_title: instance.event.series.title,
              }
            : instance
        }
      )

      context.commit(types.SET_INSTANCES_GROUPED_BY_MONTH, {
        instances,
        month: params.month,
      })
    }

    return data.getInstancesByDateRange
  },

  async getInstancePriceTypesByRef(
    context: ActionContext<State, RootState>,
    instance_ref: number
  ): Promise<any> {
    const data = await this.$instances.getInstancePriceTypesByRef(instance_ref)

    if (data.getInstanceByRef) {
      context.commit(types.SET_INSTANCE_PRICE_TYPES, data.getInstanceByRef)
    }

    return data.getInstanceByRef
  },

  async getSeriesInstancesByDateRange(
    context: ActionContext<State, RootState>,
    params: {
      alias: string
      range: { from: Date; to: Date }
      month: string
    }
  ) {
    if (context.state.instances && context.state.instances[params.month]) {
      return
    }

    const { alias, range } = params
    const data = await this.$instances.getSeriesInstancesByDateRange(
      alias,
      range
    )

    if (data) {
      const instances = data.series.instances.map((instance: Instance) => {
        return {
          ...instance,
          series_title: data.series.title,
        }
      })

      context.commit(types.SET_INSTANCES_GROUPED_BY_MONTH, {
        instances,
        month: params.month,
      })
    }

    return data
  },

  setInstanceFilters(context: ActionContext<State, RootState>, payload): void {
    context.commit(types.SET_INSTANCE_FILTERS, payload)
  },

  filterInstances(context: ActionContext<State, RootState>, payload): void {
    context.commit(types.FILTER_INSTANCES, payload)
  },
}

export default actions
