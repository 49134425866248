import { MutationTree } from 'vuex'
import { findKey } from 'lodash'
import { types, FormSubmission, UnsetSubmission } from './types'
import { State } from './state'

const mutations: MutationTree<State> = {
  [types.SET_FORM](state: State, payload: Form): void {
    state.form = payload
  },
  [types.SET_SUBMISSION](state: State, payload: FormSubmission): void {
    if (state.submissions === undefined) state.submissions = []

    if (payload.basket_item_ref) {
      const current = state.submissions.findIndex(
        (s) =>
          Number(s.basket_item_ref) === Number(payload.basket_item_ref) &&
          Number(s.ticket_number) === Number(payload.ticket_number)
      )
      if (current !== -1) {
        state.submissions[current] = payload
      } else {
        state.submissions.push(payload)
      }
    } else {
      state.submissions.push(payload)
    }
  },
  [types.SET_SUBMISSIONS](state: State, payload: FormSubmission[]): void {
    state.submissions = payload
  },
  [types.UNSET_SUBMISSION](state: State, payload: UnsetSubmission): void {
    const match = findKey(state.submissions, (sub) => {
      if (sub && sub.data) {
        return (
          parseInt(sub.data[`${payload.type}_ref`]) === parseInt(payload.ref)
        )
      }
    })
    if (match) state.submissions.splice(match, 1)
  },
  [types.UNSET_SUBMISSIONS](state: State): void {
    state.submissions = []
  },
  [types.UPDATE_CUSTOMER](state: State, payload: number | string): void {
    if (state.submissions && state.submissions.length) {
      state.submissions.forEach((sub) => {
        sub.data.customer_ref = parseInt(`${payload}`)
      })
    }
  },
  [types.UPDATE_ORDER](state: State, payload: number | string): void {
    if (state.submissions && state.submissions.length) {
      state.submissions.forEach((sub) => {
        sub.data.order_ref = parseInt(`${payload}`)
      })
    }
  },
}

export default mutations
