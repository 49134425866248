import { Event } from '@Core/@types/skyway'

/**
 * See https://app.asana.com/0/search/1205301589211939/1205843735188740
 *
 * Hack to prevent up next block flooding with Kagami events
 */
const shouldBeSuppressed = (event: Event) => {
  return event.alias && event.alias.toLowerCase().includes('kagami')
}

export const filteredUpNextEvents = (events: Event[]): Event[] | [] => {
  return events.filter((event) => {
    return (
      event.type &&
      event.type !== 'class' &&
      event.private === false &&
      !shouldBeSuppressed(event)
    )
  })
}
