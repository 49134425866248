import { DateRangeInput, Event } from '@Core/@types/skyway'
import {
  extract,
  partial_ratio,
  token_set_ratio,
  token_sort_ratio,
} from 'fuzzball'

/**
 * Filters on an array property of objects
 * returns a function that can be passed to an array filter
 *
 * @prop - { String } property - the object property we want to filter by
 * @prop - { Array } filters - the applied filters
 *
 * @return (item: any) => boolean - Function to iterate over object array
 */
const filterOnArray = (property, filters) => (item) => {
  if (filters && filters.length > 0) {
    if (item[property] && item[property].length) {
      return Array.isArray(item[property])
        ? item[property].some((val) => {
            return filters.includes(val)
          })
        : true
    }
  } else {
    return true
  }
}

/**
 * Filters on an array of strings, eg ['wheelchair', 'autism friendly']
 * returns a function that can be passed to an array filter
 *
 * @prop - { String } property - the object property we want to filter by
 * @prop - { Array } filters - the applied filters
 *
 * @return (item: any) => boolean - Function to iterate over object array
 */
const filterOnStringArray = (property, filters) => (item) => {
  if (filters && filters.length > 0) {
    // if the property exists and has items
    if (item[property] && item[property].length) {
      // check the property array against the filters array and filter those which matches
      return item[property].some((val) => {
        return filters.includes(val)
      })
    } else {
      // if the property doesn't exist on the item filter it out
      return false
    }
  } else {
    // if no filters have been applied, don't filter any out
    return true
  }
}

/**
 * Filters an exact match on an object property
 * returns a function that can be passed to an array filter
 *
 * @prop - { String } property - the object property we want to filter by
 * @prop - { Array } filters - the applied filters
 *
 * @return (item: any) => boolean - Function to iterate over object array
 */
const filterOnProp = (property, filters) => (item) => {
  return filters && filters.length && item.hasOwnProperty(property)
    ? filters.some(
        (val) => item.hasOwnProperty(property) && item[property] === val
      )
    : true
}

/**
 * Filters for a boolean true
 * returns a function that can be passed to an array filter
 *
 * @prop - { String } - property - the object property we want to filter by
 * @prop - { Boolean } applied - is the filter applied?
 *
 * @return (item: any) => boolean - Function to iterate over object array
 */
const booleanFilter = (property, applied: boolean) => (item) => {
  const result = applied
    ? item.hasOwnProperty(property) && item[property] === true
    : true

  return result
}

/**
 * Filters by a date range
 * returns a function that can be passed to an array filter
 *
 * @prop - { DateRangeInput } - the date range to apply
 * @prop - { Moment } moment - Moment passed from global plugin
 * @prop - { String } key - The object key to filter on
 *
 * @return (item: any) => boolean - Function to iterate over object array
 */
const filterByDate =
  (range: DateRangeInput, moment, key: string = 'last_date') =>
  (item: Event) => {
    if (range) {
      if (range.from && range.to) {
        return (
          moment(item.first_date).isBefore(moment(range.to)) &&
          moment(range.from).isBefore(moment(item.last_date))
        )
      } else if (range.from) {
        return moment(item[key]).isAfter(moment(range.from))
      } else if (range.to) {
        return moment(item[key]).isBefore(moment(range.to))
      }
    }

    return true
  }

const searchAll = (items: Event[], term: string): Event[] => {
  if (term === '' || term.length < 3) {
    return items
  }
  // Tweak the settings here if search results are bad
  // see https://github.com/nol13/fuzzball.js
  const options = {
    scorer: (query, choice, options) => {
      const score1 = token_set_ratio(query, choice.title, {
        sortBySimilarity: true,
      })
      const score2 = token_sort_ratio(query, choice.title)
      const score3 = partial_ratio(query, choice.title) - 10

      const score4 = token_sort_ratio(query, choice.production_title)
      const score5 = token_sort_ratio(query, choice.production_title)
      const score6 = partial_ratio(query, choice.production_title) - 20

      return Math.max(
        ...[score1, score2, score3, score4, score4, score5, score6]
      )
    },
    limit: 9,
    cutoff: 60,
  }

  /**
   * Results are returned as an array containing the actual result
   * plus scoring, we could use the scoring to sort the result
   * if necessary to improve experience
   */
  return extract(term, items, options).reduce((acc: any[], result) => {
    if (result[0]) {
      acc.push(result[0])
    }
    return acc
  }, [])
}

const filterFunctions = (filters, moment) => {
  return [
    filterOnProp('type', filters.eventTypes),
    filterOnArray('tagsFlat', filters.eventTags),
    filterOnStringArray('accessTags', filters.accessibility),
    filterOnArray('tagsFlat', filters.focus),
    filterByDate(filters.dateRange, moment),
    booleanFilter('free', filters.free),
    filterOnProp('age_range', filters.age),
  ]
}

const filterData = (data, filters, moment) => {
  return filterFunctions(filters, moment).reduce((data, func) => {
    return data.filter(func)
  }, data)
}

export {
  filterOnArray,
  filterOnStringArray,
  filterByDate,
  booleanFilter,
  filterOnProp,
  filterFunctions,
  filterData,
  searchAll,
}
