import { Pages } from '@Events/config/imports'
import { config } from '@Core/config/skyway'

export const ROUTE_NAME = {
  EVENTS: 'events',
  EVENT: 'event',
  EVENT_PREVIEW: 'event-preview',
  SERIES_EVENT: 'series-event',
  EVENTS_FILTERED: 'events-archive',
  SERIES_LANDING: 'series-landing-id',
}

export default [
  {
    name: ROUTE_NAME.EVENTS,
    path: config.URLS.whats_on,
    component: Pages.Events,
  },
  {
    name: ROUTE_NAME.EVENTS_FILTERED,
    path: `${config.URLS.whats_on}archive`,
    component: Pages.Events,
  },
  {
    name: ROUTE_NAME.EVENT,
    path: `${config.URLS.whats_on}:id`,
    component: Pages.Event,
  },
  {
    name: ROUTE_NAME.EVENT_PREVIEW,
    path: `/events/:id`,
    component: Pages.EventPreviewPage,
  },
  {
    name: ROUTE_NAME.SERIES_EVENT,
    path: `${config.URLS.whats_on}:series/:id`,
    component: Pages.Event,
  },
  {
    name: ROUTE_NAME.SERIES_LANDING,
    path: '/series/:id?',
    component: Pages.SeriesLandingPage,
  },
]
