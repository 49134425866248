import { ActionTree, ActionContext } from 'vuex'
import { RootState } from '@Core/store/types'
import * as customMessages from '@CustomMessages/api/queries/custom-messages.gql'
import { groupBy } from 'lodash'
import { State } from './state'
import { types } from './types'

const actions: ActionTree<State, RootState> = {
  async getCustomMessagesByPageSlug(
    context: ActionContext<State, RootState>,
    slug: string
  ) {
    const client = this.app.$apollo

    const response = await client.query({
      query: customMessages.getCustomMessagesByPageSlug,
      variables: {
        slug,
      },
    })

    const { data } = response

    context.commit(types.SET_MESSAGES, {
      slug,
      messages: data.getCustomMessagesByPageSlug,
    })

    return data.getCustomMessagesByPageSlug
  },

  async getCustomMessages(context: ActionContext<State, RootState>) {

    if (!Object.values(context.state.messages).length) {
      const client = this.app.$apolloNonPersisted

      const response = await client.query({
        query: customMessages.getCustomMessages,
      })

      const { data } = response

      const messages = groupBy(
        data.getCustomMessages.map((m) => {
          return {
            page: m.page.slug,
            message: {
              slug: m.slug,
              content: m.content,
            },
          }
        }),
        'page'
      )

      for (const [key, value] of Object.entries(messages)) {
        const pageMessages = value.map((msg) => msg.message)
        context.commit(types.SET_MESSAGES, {
          slug: key,
          messages: pageMessages,
        })
      }
    }

    return context.state.messages
  },

  getCustomMessage(
    context: ActionContext<State, RootState>,
    { page, slug, def }
  ) {
    let msg
    if (
      context.state.messages &&
      context.state.messages[page] &&
      context.state.messages[page].length
    ) {
      const result = context.state.messages[page].find((m) => m.slug === slug)

      msg = result && result.content ? result.content : ''
    }
    return msg || def || ''
  },
}

export default actions
