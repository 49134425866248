import { Form } from '@Core/@types/skyway'
import { FormSubmission } from './types'

export interface State {
  form?: Form
  errors?: any
  submissions?: FormSubmission[]
}

/**
 * Initial state, empty array of forms
 */
export const state = (): State => ({
  form: undefined,
  errors: undefined,
  submissions: [],
})

export default state
