import { GetterTree } from 'vuex'
import { State } from './state'
import { RootState } from '@Core/store/types'
import { Instance, PriceType } from '@Core/@types/skyway'
import { buildDynamicQuery } from '@Core/helpers/filters'

const getters: GetterTree<State, RootState> = {
  priceTypes: (state: State): PriceType[] => {
    return (state.instance && (state.instance.price_types as PriceType[])) || []
  },
  basePrice: (state: State, getters): PriceType | false => {
    if (!getters.priceTypes) {
      return false
    }
    let basePrice = getters.priceTypes.find((pt) => pt && pt.base && pt.enabled)

    if (!basePrice) {
      basePrice = getters.priceTypes.find((pt) => pt && pt.best && pt.enabled)
    }

    if (!basePrice) {
      basePrice = getters.priceTypes.find((pt) => pt && pt.enabled)
    }

    return basePrice || false
  },
  promoPrice: (state: State, getters): PriceType | false => {
    if (!getters.priceTypes) {
      return false
    }
    let promo = getters.priceTypes.find((pt) => pt && pt.offer && pt.enabled)

    if (!promo) {
      promo = getters.priceTypes.find(
        (pt) =>
          pt &&
          pt.title &&
          pt.title.toLowerCase().includes('promo') &&
          pt.enabled
      )
    }

    return promo || false
  },

  filterPriceTypesByName:
    (state: State, getters) =>
    (filters: string[], operator: 'OR' | 'AND' = 'OR'): PriceType[] => {
      const types = getters.priceTypes

      const filterFunctions = buildDynamicQuery(filters, 'title')

      return types && operator === 'AND'
        ? types.filter((pt) => filterFunctions.every((f) => f(pt)))
        : types.filter((pt) => filterFunctions.some((f) => f(pt)))
    },

  instancesByDay: (state: State) => (month: string, dateHandler) => {
    const groupInstancesByDay = (instances) => {
      if (instances && instances[month]) {
        return instances[month].reduce((instancesArr, instance) => {
          const key = dateHandler(instance.date_time).format('ddd D')
          const currentVal = instancesArr[key] ?? []

          return { ...instancesArr, [key]: [...currentVal, instance] }
        }, {})
      } else {
        return null
      }
    }

    if (state.filteredInstances && state.filteredInstances[month]) {
      return groupInstancesByDay(state.filteredInstances)
    } else {
      return groupInstancesByDay(state.instances)
    }
  },
}

export default getters
