import { MutationTree } from 'vuex'
import { types } from './types'
import { State } from './state'

const mutations: MutationTree<State> = {
  [types.SET_MESSAGES](
    state: State,
    payload: { slug: string; messages: CustomMessage[] }
  ): void {
    state.messages[payload.slug] = payload.messages
  },
}

export default mutations
