export const types = {
  SET_INSTANCE: 'SET_INSTANCE',
  SET_SELECTED: 'SET_SELECTED',
  SET_INSTANCES: 'SET_INSTANCES',
  SET_INSTANCE_AVAILABILITY: 'SET_INSTANCE_AVAILABILITY',
  SET_INSTANCE_PRICE_TYPES: 'SET_INSTANCE_PRICE_TYPES',
  SET_ZONE: 'SET_ZONE',
  SET_INSTANCES_GROUPED_BY_MONTH: 'SET_INSTANCES_GROUPED_BY_MONTH',
  FILTER_INSTANCES: 'FILTER_INSTANCES',
  SET_INSTANCE_FILTERS: 'SET_INSTANCE_FILTERS',
  RESET: 'RESET',
}
