import { Instance, Zone } from '@Core/@types/skyway'
import { WEB_MOS_REFS } from '@Tickets/store/seats/state'

export interface State {
  instance?: Instance
  instances: Instance[] | { [key: string]: Instance[] }
  instancesGroupedByMonth: Object | null
  filteredInstances: Object | null
  instanceFilters: string[]
  selected?: Instance
  zone?: Zone
  web_mos_refs: string[]
}

export const defaultState = (): State => ({
  instance: undefined,
  instances: [],
  instancesGroupedByMonth: null,
  instanceFilters: [],
  filteredInstances: null,
  selected: undefined,
  zone: undefined,
  web_mos_refs: WEB_MOS_REFS,
})

const state = defaultState

export default state
