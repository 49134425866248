import { GetterTree } from 'vuex'
import { groupBy } from 'lodash'
import { State } from './state'

export const getters: GetterTree<State, RootState> = {
  // getMessage:
  //   (state: State): Customer | undefined =>
  //   (page: string, slug: string, def: string = '') => {
  //     console.log(page, slug, def)
  //     let msg
  //     if (
  //       state.messages &&
  //       state.messages[page] &&
  //       state.messages[page].length
  //     ) {
  //       const result = state.messages[page].find((m) => m.slug === slug)

  //       msg = result.content
  //     }
  //     return msg || def
  //   },
}

export default getters
