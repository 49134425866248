import { ActionTree, ActionContext } from 'vuex'
import { RootState } from '@Core/store/types'
import { Event, DateRangeInput, Series } from '@Core/@types/skyway'
import { State } from './state'
import { types } from './types'

const actions: ActionTree<State, RootState> = {
  async getEvents(
    context: ActionContext<State, RootState>,
    query: string = 'getEvents'
  ): Promise<Event[]> {
    const data = await this.$events.getEvents(query)

    context.commit(types.SET_EVENTS, data.getEvents)

    return data.getEvents
  },

  async getEventsByDate(
    context: ActionContext<State, RootState>,
    range: DateRangeInput
  ): Promise<Event[]> {
    const data = await this.$events.getEventsByDate(range)

    context.commit(types.SET_EVENTS, data.getEvents)

    return data.getEvents
  },

  async getEventByRef(
    context: ActionContext<State, RootState>,
    params: { event_ref: string; range: DateRangeInput; preview: boolean }
  ): Promise<Event> {
    const preview = params.preview === true ? true : false
    const data = await this.$events.getEventByRef(
      params.event_ref,
      params.range,
      'cache-first',
      preview
    )

    context.commit(types.SET_EVENT, data.getEventByRef)

    return data.getEventByRef
  },

  async getEventById(
    context: ActionContext<State, RootState>,
    params: { id: string; range: DateRangeInput; preview: boolean }
  ): Promise<Event> {
    const preview = params.preview === true ? true : false
    const data = await this.$events.getEventById(
      params.id,
      params.range,
      'network-only',
      preview
    )

    context.commit(types.SET_EVENT, data.event)

    return data.event
  },

  async getEventAvailability(
    context: ActionContext<State, RootState>,
    params: {
      event_ref: string
      range: DateRangeInput
    }
  ): Promise<Event> {
    const data = await this.$events.getEventAvailability(
      params.event_ref,
      params.range,
      false
    )

    if (data && data.getEventByRef) {
      context.commit(types.SET_EVENT_AVAILABILITY, data.getEventByRef)
    }

    return data.getEventByRef
  },

  async getInstancesByEventRef(
    context: ActionContext<State, RootState>,
    params: {
      event_ref: string
      range: DateRangeInput
    }
  ): Promise<Event | false> {
    const data = await this.$events.getEventInstancesByRef(
      params.event_ref,
      params.range,
      'network-only'
    )

    if (data && data.getEventByRef) {
      context.commit(types.SET_EVENT_INSTANCES, data.getEventByRef)

      return data.getEventByRef
    }

    return false
  },

  async getEventPrices(
    context: ActionContext<State, RootState>,
    event_ref: string
  ): Promise<Event> {
    const data = await this.$events.getEventPrices(event_ref)

    context.commit(types.SET_EVENT_PRICING, data.getEventByRef)

    return data.getEventByRef
  },

  async getEventBySlug(
    context: ActionContext<State, RootState>,
    slug: string
  ): Promise<any> {
    const data = await this.$events.getEventBySlug(slug, 'cache-first')

    context.commit(types.SET_EVENT, data.getEventBySlug)

    return data.getEventBySlug
  },

  async getTimedEventByRef(
    context: ActionContext<State, RootState>,
    event_ref: string
  ): Promise<any> {
    const data = await this.$events.getTimedEventByRef(event_ref, 'cache-first')

    context.commit(types.SET_EVENT, data.getEventByRef)

    return data.getEventByRef
  },

  async getEventMetaBySlug(
    context: ActionContext<State, RootState>,
    slug: number,
    fetchPolicy: string = 'no-cache'
  ): Promise<Event> {
    const data = await this.$events.getEventMetaBySlug(slug, 'cache-first')

    return data.getEventBySlug
  },

  async getSeries(context: ActionContext<State, RootState>): Promise<any> {
    const data = await this.$events.getSeries()

    return data.getSeries
  },

  async getAndCommitSeries(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    const data = await this.$events.getSeries()

    if (data.getSeries) {
      context.commit(types.SET_SERIES, data.getSeries)
    }

    return data.getSeries
  },

  async getSeriesByAlias(
    context: ActionContext<State, RootState>,
    alias: string
  ): Promise<any> {
    const data = await this.$events.getSeriesByAlias(alias)
    return data.getSeriesByAlias
  },

  async getAndCommitSeriesByAlias(
    context: ActionContext<State, RootState>,
    alias: string
  ): Promise<any> {
    const data = await this.$events.getSeriesByAlias(alias)

    if (data && data.series) {
      context.commit(types.SET_SINGLE_SERIES, data.series)
    }

    return data
  },

  getActiveFilters(context: ActionContext<State, RootState>) {
    const tags = context.rootGetters['events/tags']

    context.commit('SET_ACTIVE_FILTERS', tags)
  },

  updateActiveFilters(context: ActionContext<State, RootState>, activeFilters) {
    context.commit('UPDATE_ACTIVE_FILTERS', activeFilters)
  },

  removeAllActiveFilters(context: ActionContext<State, RootState>) {
    context.commit('REMOVE_ALL_ACTIVE_FILTERS')
  },
}

export default actions
