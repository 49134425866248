export interface State {
    messages?: object
  }
  
  /**
   * Initial state, empty array of forms
   */
  export const state = (): State => ({
    messages: {},
  })

export default state
