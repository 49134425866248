export const whitelist = [
  'relaxed',
  'signed',
  'captioned',
  'audio_assist',
  'autism_friendly',
]

export const parseAccessibilityTags = (tags) => {
  if (tags) {
    return Object.entries(tags).reduce((arr: string[], [key, val]) => {
      if (val && whitelist.includes(key)) {
        const tag = key.replace(/_/g, ' ')
        if (tag === 'audio assist') {
          arr.push('audio described')
        } else if (tag === 'captioned') {
          arr.push('subtitled')
        } else {
          arr.push(tag)
        }
      }

      return arr
    }, [])
  } else {
    return null
  }
}

export const getAccessibilityTags = (tagsArr: string[]): string[] | null => {
  const tags: string[] = []

  tagsArr.forEach((tag: string) => {
    if (whitelist.includes(tag)) {
      tags.push(tag)
    }
  })

  return tags.length > 0 ? tags : null
}
