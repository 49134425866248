import { GetterTree } from 'vuex'
import { RootState } from '@Core/store/types'
import { Availability, Event, Instance, CmsImage } from '@Core/@types/skyway'
import { State } from './state'
import { formatImage } from '@Core/helpers/imageFormatter'
import { filteredUpNextEvents } from '@Events/helpers/filterUpNextEvents'

type InstanceTransformed = Instance & {
  workshop: {
    apply_by_date?: string
    max_age?: string
    min_age?: string
  }
  almost_gone: boolean
}

export const indexEventInstancesByMonth = (events, dateHandler) => {
  return (
    events &&
    events.reduce((instancesArr: {}, event: Event) => {
      if (!event.private && event.type !== 'series' && event.instances) {
        event.instances.forEach((instance) => {
          const instance_date = dateHandler(instance?.date_time).format(
            'YYYY-MM-DD'
          )
          const data = transformInstance(instance, event)

          instancesArr[instance_date] = instancesArr[instance_date] || []
          if (
            !instancesArr[instance_date].some(
              (i) => i.instance_ref === data.instance_ref
            )
          ) {
            instancesArr[instance_date].push(data)
          }
        })
      }

      return instancesArr
    }, {})
  )
}

const transformInstance = (inst, event): InstanceTransformed => {
  return {
    ...inst,
    subtitle: event.subtitle,
    event_ref: event.event_ref, // for the event purchase page url
    alias: event.alias, // for the event page url,
    accessibility: event.accessibility,
    series: {
      title: event.series?.title,
    },
    workshop: {
      apply_by_date: event.extra && event.extra.workshop_apply_by_date,
      max_age: event.extra && event.extra.workshop_max_age,
      min_age: event.extra && event.extra.workshop_min_age,
    },
    additional: event.additional,
    almost_gone: event.extra && event.extra.almost_gone,
  }
}

const getters: GetterTree<State, RootState> = {
  tags: (state: State) => {
    if (state.event && state.event.instances) {
      const tags = state.event.instances.reduce(
        (acc: string[], singleEvent) => {
          if (singleEvent && singleEvent.tags) {
            for (const tag of singleEvent.tags) {
              if (tag && tag.tag && !acc.includes(tag.tag)) {
                acc.push(tag.tag)
              }
            }
          }

          return acc
        },
        []
      )

      return tags
    } else {
      return []
    }
  },

  filtersApplied: (state: State) => {
    let result = false

    for (const f in Object.keys(state.filters)) {
      if (
        ['accessibility', 'eventTypes', 'eventTags'].includes(f) &&
        state.filters[f].length > 0
      ) {
        result = true
      }
    }

    return state.filters.free || result
  },

  eventInstancesByMonth:
    (state: State, getters) =>
    (dateHandler): { [key: string]: InstanceTransformed[] } => {
      return indexEventInstancesByMonth(state.events, dateHandler)
    },

  activeFilters: (state: State) => {
    return state.activeFilters
  },

  featuredEvents: (state: State) => (maxCount?: number) => {
    const events: Event[] = []

    if (state.events) {
      for (const event of state.events) {
        if (event.featured) {
          events.push(event)
        }

        if (maxCount && events.length === maxCount) {
          break
        }
      }
    }

    return events
  },

  upNextEvents: (state: State) => {
    const { events } = state

    return filteredUpNextEvents(events).slice(0, 15)
  },

  seriesByAlias: (state: State) => (alias: string) => {
    if (state.series) {
      return state.series.find((series) => series.alias === alias) || false
    }
    return false
  },

  seriesById: (state: State) => (id: number) => {
    if (state.series) {
      return state.series.find((series) => series.id === id) || false
    }
    return false
  },

  availabilityByEventRef: (state: State) => (event_ref: string) => {
    const event =
      state.event && state.event.event_ref === event_ref ? state.event : false

    if (event && event.instances) {
      const availability: Availability[] = event.instances.reduce(
        (acc: Availability[], inst) => {
          if (inst && inst.availability) {
            for (const a of inst.availability as Availability[]) {
              a && acc.push(a)
            }
          }
          return acc
        },
        []
      )

      return availability
    }

    return []
  },

  eventImage: (state: State) => {
    return state.event && state.event.images
      ? formatImage(state.event.images)
      : null
  },

  suggestedEvents: (state: State) => (showClasses: boolean) => {
    if (state.events) {
      const onsaleEvents = state.events.reduce(
        (events: Event[], event: Event): Event[] | [] => {
          if (event.on_sale) {
            if (showClasses && event.type === 'class') {
              return [...events, event]
            } else if (!showClasses && event.type === 'event') {
              return [...events, event]
            }
          }

          return events
        },
        []
      )

      const sortedOnsaleEvents = onsaleEvents.sort(
        (a, b) => a.first_date - b.first_date
      )

      return sortedOnsaleEvents
    } else {
      return null
    }
  },
}

export default getters
