import { GetterTree } from 'vuex'
import { groupBy } from 'lodash'
import { State } from './state'

export const getters: GetterTree<State, RootState> = {
  submissionsByInstance(state: State, getters: any): any[] {
    if (state.submissions && state.submissions.length) {
      const submissionData: any[] = state.submissions.map((s) => s.data)
      const byInstance = groupBy(submissionData, (data) => data.instance_ref)
      return byInstance
    } else {
      return []
    }
  },
}

export default getters
