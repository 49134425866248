import { Pages } from '@Forms/config/imports'

export const ROUTE_NAME = {
  FORM: 'form'
}

export default [
  {
    path: '/forms/:id?',
    component: Pages.Form,
    name: ROUTE_NAME.FORM,
  },
]
