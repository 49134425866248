


























import { Component, mixins, namespace } from 'nuxt-property-decorator'
import { Event } from '@Core/@types/skyway'
import { Route } from 'vue-router'
import HeaderPart from '@Core/components/parts/header/HeaderPart.vue'
import FooterPart from '@Core/components/parts/footer/FooterPart.vue'
import ResizeObserver from '@UI/components/resize-oberserver/ResizeObserver.vue'
import AlertNotification from '@UI/components/notifications/AlertNotification.vue'
import PromoCodeHandler from '@Core/mixins/PromoCodeHandler.vue'

const events = namespace('events')

@Component({
  components: {
    HeaderPart,
    FooterPart,
    ResizeObserver,
    AlertNotification,
  },
  head() {
    return {
      titleTemplate: '%s | Roundhouse',
    }
  },
})
export default class EventLayout extends mixins<PromoCodeHandler>(
  PromoCodeHandler
) {
  @events.State
  event!: Event

  get breadcrumbs(): { title: string; href: Route }[] {
    if (process.server) {
      return []
    }
    const fullPath = this.$route.path.replace(/\/$/, '')
    const params = fullPath.startsWith('/')
      ? fullPath.substring(1).split('/')
      : fullPath.split('/')
    const crumbs: { title: string; href: Route }[] = []
    let path = ''
    params.forEach((param, index) => {
      path = `${path}/${param}`
      const match = this.$router.match(path)
      if (match.name !== null) {
        let title
        if (index === params.length - 1) {
          title = (this.event && this.event.title) || param.replaceAll('-', ' ')
        } else {
          title = param.replaceAll('-', ' ')
        }

        if (this.$route.name === 'series-event' && match.name === 'event') {
          const href = {
            ...match,
            path: match.path.replace('whats-on', 'series'),
            fullPath: match.path.replace('whats-on', 'series'),
          }
          crumbs.push({
            title,
            href,
          })
        } else {
          crumbs.push({
            title,
            href: match,
          })
        }
      }
    })
    return crumbs
  }
}
