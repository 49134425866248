import { ActionTree, ActionContext } from 'vuex'
import { RootState } from '@Core/store/types'
import * as forms from '@Forms/api/queries/forms.gql'
import { State } from './state'
import { types } from './types'

const actions: ActionTree<State, RootState> = {
  async getForm(
    context: ActionContext<State, RootState>,
    id: number
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.query({
      query: forms.getForm,
      variables: {
        id,
      },
    })

    const { data } = response

    if (data && data.form) {
      context.commit(types.SET_FORM, data.form)

      return data.form
    }

    return false
  },

  /**
   * Submit a form
   */
  async submitForm(
    context: ActionContext<State, RootState>,
    input: FormSubmission
  ): Promise<any> {
    const client = this.app.$apollo
    const response = await client.mutate({
      mutation: forms.submitForm,
      variables: {
        id: input.id,
        data: Object.assign(input.data, {
          customer_ref: this.state?.customer?.customer?.customer_ref || null,
        }),
      },
    })

    const { data } = response

    return data.submitForm
  },

  /**
   * Store a form to be submitted once checkout is complete
   */
  deferForm(
    context: ActionContext<State, RootState>,
    input: FormSubmission
  ): boolean {
    context.commit(types.SET_SUBMISSION, input)
    return true
  },

  /**
   * Process all submissions
   */
  async processSubmissions(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    if (context.state.submissions !== undefined) {
      const self = this

      if (this.state.customer.customer === undefined) {
        await this.dispatch('customer/getActiveCustomer')
      }

      const results = context.state.submissions.map(async (input) => {
        const inputData = JSON.parse(JSON.stringify(input.data))

        const client = this.app.$apollo
        const response = await client.mutate({
          mutation: forms.submitForm,
          variables: {
            id: input.id,
            data: Object.assign(inputData, {
              customer_ref: self.state.customer.customer.customer_ref,
            }),
          },
        })

        const { data } = response

        return data.submitForm
      })

      return Promise.all(results).then((res) => {
        context.commit(types.UNSET_SUBMISSIONS)
        return true
      })
    }
  },

  async cleanSubmissions(
    context: ActionContext<State, RootState>
  ): Promise<any> {
    if (context.state.submissions !== undefined) {
      const basket = await this.dispatch('basket/getBasket', {
        fetchPolicy: 'cache-first',
        hasSeatingConfig: true,
      })
      let basketItemRefs: number[] = []

      if (basket && basket.groups.length) {
        const output: any[] = []
        basket.groups.forEach((group) => {
          if (group.name === 'Performance' || group.name === 'Package') {
            group.items.forEach((i) => {
              output.push(i)
            })
          }
        })
        basketItemRefs = output.map((t) => parseInt(t.item_ref))
      }

      // get unique forms
      const submissions = context.state.submissions.filter(
        (object, index) =>
          context.state.submissions &&
          index ===
            context.state.submissions.findIndex(
              (obj) => JSON.stringify(obj) === JSON.stringify(object)
            )
      )

      const output: FormSubmission[] = []
      for (const submission in submissions) {
        const input = submissions[submission]

        if (input.basket_item_ref) {
          // if form is linked to an basket item which is no longer in the basket, ignore it
          if (!basketItemRefs.includes(input.basket_item_ref)) continue
        }
        output.push(input)
      }
      context.commit(types.SET_SUBMISSIONS, output)
    }
  },
}

export default actions
