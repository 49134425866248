import { Vue } from 'nuxt-property-decorator'
import { MutationTree } from 'vuex'
import _cloneDeep from 'lodash/cloneDeep'
import { Event, Instance, Series } from '@Core/@types/skyway'
import {
  transformSingleEvent,
  transformEvents,
} from '@Events/helpers/transformEvents'
import { State, defaultState } from './state'
import { types } from './types'

type ISeries = Event & Series

const isPropValuesEqual = (subject: any, target: any, propNames: string[]) =>
  propNames.every((propName) => subject[propName] === target[propName])

const getUniqueItemsByProperties = (items: any[], propNames: string[]) =>
  items.filter(
    (item, index, array) =>
      index ===
      array.findIndex((foundItem) =>
        isPropValuesEqual(foundItem, item, propNames)
      )
  )

export const mutations: MutationTree<State> = {
  [types.SET_EVENTS](state: State, payload: Event[]): void {
    if (payload && Array.isArray(payload)) {
      const transformed = transformEvents(payload, state)
      for (const item of transformed) {
        const existing = state.events.find(
          (i) => i.event_ref === item.event_ref
        )
        if (existing) {
          Object.assign(existing, item)
        } else {
          state.events.push(item)
        }
      }
    }
  },
  [types.ADD_SERIES_TO_EVENTS](state: State, payload: ISeries[]): void {
    for (const s of payload) {
      if (s.events && s.events[0]) {
        s.first_date = s.events[0].first_date
        s.last_date = s.events[s.events.length - 1]?.last_date
        s.type = 'series'
        s.extra = {
          drop_in: null,
        }

        if (!state.events.find((e) => e.type === 'series' && e.id === s.id)) {
          state.events.push(s)
        }
      }
    }
    state.events.sort((a, b) => {
      if (a.extra.drop_in === true && b.extra.drop_in !== true) {
        return 1
      }
      if (b.extra.drop_in === true && a.extra.drop_in !== true) {
        return -1
      }
      return a.first_date > b.first_date ? 1 : -1
    })
  },
  [types.SET_SERIES](state: State, payload: Series[]): void {
    state.series = payload
  },
  [types.SET_SINGLE_SERIES](state: State, payload: Series): void {
    state.singleSeries = payload
  },
  [types.SET_EVENT](state: State, payload: Event): void {
    const event = transformSingleEvent(payload, state)
    const related = transformEvents(payload.related as Event[], state)

    state.event = { ...event, related }

    if (state.event && state.event.title) {
      state.event.title = state.event.title?.replace(
        /(\d{1,2})([a-z]{2})/,
        '$1<sup>$2</sup>'
      )
    }
  },
  [types.RESET_EVENT](state: State) {
    state.event = undefined
  },
  [types.SET_EVENT_INSTANCES](state: State, payload: Event): void {
    if (state.event && payload.event_ref === state.event.event_ref) {
      Vue.set(
        state.event,
        'instances',
        getUniqueItemsByProperties(payload.instances || [], ['instance_ref']) ||
          []
      )
    }
  },
  [types.SET_EVENT_PRICING](state: State, payload): void {
    if (state.event && payload && payload.instances) {
      if (!state.event.instances) {
        Vue.set(state.event, 'instances', [])
      }
      this.commit('events/UPDATE_INSTANCE', payload.instances)
    }
  },
  [types.SET_SELECTED](state: State, payload: Event): void {
    state.selected = payload
  },
  [types.SET_ACTIVE_FILTERS](
    state: State,
    payload: { key: string; value: any }
  ) {
    if (state.filters.hasOwnProperty(payload.key)) {
      state.filters[payload.key] = payload.value
    }
    /**
     * If switching to Workshops only, show them in date order
     */
    if (payload.key === 'eventTypes' && payload.value.includes('class')) {
      state.events.sort((a, b) => (a.first_date > b.first_date ? 1 : -1))
    } else if (payload.key === 'eventTypes') {
      /**
       * If showing all, push drop ins to the end of the list
       *
       * @todo - extract the sort functions into named methods for clarity
       */
      state.events.sort((a, b) => {
        if (a.extra.drop_in === true && b.extra.drop_in !== true) {
          return 1
        }
        if (b.extra.drop_in === true && a.extra.drop_in !== true) {
          return -1
        }
        return a.first_date > b.first_date ? 1 : -1
      })
    }
  },
  [types.RESET_FILTERS](state: State) {
    state.filters = {
      eventTypes: [],
      eventTags: [],
      accessibility: [],
      focus: [],
      age: [],
      dateRange: {
        from: undefined,
        to: undefined,
      },
      free: false,
    }
  },
  [types.SET_EVENT_AVAILABILITY](state: State, payload: Event): void {
    if (
      state.event &&
      payload.instances &&
      payload.event_ref === state.event.event_ref
    ) {
      state.eventAvailability = payload && payload.instances
      if (!state.event.instances) {
        Vue.set(state.event, 'instances', [])
      }
      this.commit('events/UPDATE_INSTANCE', payload.instances)
    }
  },
  [types.UPDATE_INSTANCE](state: State, payload: Instance[]) {
    for (let i = 0; i < payload.length; i++) {
      const instance = payload[i]
      const storeInstance = state.event?.instances?.find(
        (inst) =>
          inst && Number(inst.instance_ref) === Number(instance.instance_ref)
      )
      if (storeInstance && state.event && state.event.instances) {
        state.event.instances = [
          ...state.event.instances.filter(
            (inst) => inst && inst.instance_ref !== instance.instance_ref
          ),
          { ...storeInstance, ...instance },
        ]
      }
    }
  },
  [types.RESET](state: State): void {
    Object.assign(state, defaultState())
  },
}

export default mutations
