export const types = {
  SET_EVENTS: 'SET_EVENTS',
  SET_EVENT: 'SET_EVENT',
  ADD_SERIES_TO_EVENTS: 'ADD_SERIES_TO_EVENTS',
  SET_SERIES: 'SET_SERIES',
  SET_SINGLE_SERIES: 'SET_SINGLE_SERIES',
  SET_SELECTED_DATE: 'SET_SELECTED_DATE',
  SET_CALENDAR: 'SET_CALENDAR',
  SET_SELECTED: 'SET_SELECTED',
  SET_ACTIVE_FILTERS: 'SET_ACTIVE_FILTERS',
  UPDATE_ACTIVE_FILTERS: 'UPDATE_ACTIVE_FILTERS',
  REMOVE_ALL_ACTIVE_FILTERS: 'REMOVE_ALL_ACTIVE_FILTERS',
  SET_EVENT_AVAILABILITY: 'SET_EVENT_AVAILABILITY',
  SET_EVENT_INSTANCES: 'SET_EVENT_INSTANCES',
  SET_EVENT_PRICING: 'SET_EVENT_PRICING',
  FILTER_EVENTS: 'FILTER_EVENTS',
  RESET_FILTERS: 'RESET_FILTERS',
  RESET_EVENT: 'RESET_EVENT',
  UPDATE_INSTANCE: 'UPDATE_INSTANCE',
  RESET: 'RESET',
}
