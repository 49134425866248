import { Pages } from '@Gifts/config/imports'

export const ROUTE_NAME = {
  GIFT_MEMBERSHIP: 'gift-membership',
  GIFT_MEMBERSHIP_LEVEL: 'gift-membership-level',
  GIFT_VOUCHER: 'gift-voucher',
  REDEEM_GIFT_MEMBERSHIP: 'redeem-gift-membership',
}

export default [
  {
    path: '/gifts/membership',
    component: Pages.Membership,
    name: ROUTE_NAME.GIFT_MEMBERSHIP,
  },
  {
    path: '/gifts/membership/:id?',
    component: Pages.Membership,
    name: ROUTE_NAME.GIFT_MEMBERSHIP_LEVEL,
  },
  {
    path: '/gifts/voucher',
    component: Pages.Voucher,
    name: ROUTE_NAME.GIFT_VOUCHER,
  },
  {
    path: '/gifts/redeem-gift-membership',
    component: Pages.RedeemGiftMembership,
    name: ROUTE_NAME.REDEEM_GIFT_MEMBERSHIP,
  },
]
