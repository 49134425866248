import { Programme } from '@Core/@types/types'
import type { Event, ModeOfSale, Tag, Maybe } from '@Core/@types/skyway'
import { parseAccessibilityTags } from '@Events/helpers/accessibilityTags'
// import { mockMos } from '@Core/helpers/mos'

interface Entity {
  modes_of_sale?: Maybe<Maybe<ModeOfSale>[]>
}

export const filterValidMos = <T extends Entity>(state: any, entity: T): T => {
  const validMos: Maybe<ModeOfSale>[] =
    entity.modes_of_sale?.filter((mos) =>
      state.web_mos_refs?.includes(mos?.mode_of_sale_ref)
    ) || []
  return {
    ...entity,
    modes_of_sale: validMos,
  }
}

const flattenTags = (event: Event) => {
  if (event.tags) {
    return event.tags.reduce(
      (tags: [] | string[], tag: Maybe<Tag>): [] | string[] => {
        if (tag && tag.tag) {
          return [...tags, tag.tag.toLowerCase()]
        }

        return [...tags]
      },
      []
    )
  } else {
    return []
  }
}

const parseAgeRange = (event: Event) => {
  const { workshop_min_age, workshop_max_age } = event.extra || {}

  return workshop_min_age && workshop_max_age
    ? `${String(workshop_min_age).replace(/\D/g, '')}-${String(
        workshop_max_age
      ).replace(/\D/g, '')}`
    : null
}

export const transformSingleEvent = (event: Event, state?: any): Programme => {
  const { drop_in } = event.extra || false

  const instances = event.instances
    ? event.instances.filter(
        (v, i, a) =>
          a.map((e) => e?.instance_ref).indexOf(v?.instance_ref) === i
      )
    : []

  return {
    ...event,
    instances: state
      ? instances.map((i) => i && filterValidMos(state, i))
      : instances,
    modes_of_sale:
      state && event.modes_of_sale
        ? filterValidMos(state, event).modes_of_sale
        : event.modes_of_sale,
    tagsFlat: flattenTags(event),
    age_range: parseAgeRange(event),
    drop_in,
    accessTags: parseAccessibilityTags(event.accessibility),
  }
}

export const transformEvents = (
  events: Event[],
  state?: any
): Programme[] | [] => {
  if (events && events.length > 0) {
    return events.reduce(
      (eventsArr: Programme[] | [], event: Event): Programme[] | [] => {
        if (event) {
          const transformedEvent = transformSingleEvent(event, state)

          return [...eventsArr, transformedEvent]
        }

        return eventsArr
      },
      []
    )
  } else {
    return []
  }
}
